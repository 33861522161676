/* src/fontStyles.css */
@font-face {
  font-family: 'ProspectusPro';
  src: url('./fonts/ProspectusPro_Personal_License/Prospectus Pro Demo Fonts v1-73/web/ProspectusProLDEMO-Black.woff2') format('woff2'),
       url('./fonts/ProspectusPro_Personal_License/Prospectus Pro Demo Fonts v1-73/web/ProspectusProLDEMO-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'ProspectusPro';
  src: url('./fonts/ProspectusPro_Personal_License/Prospectus Pro Demo Fonts v1-73/web/ProspectusProLDEMO-BlackItalic.woff2') format('woff2'),
       url('./fonts/ProspectusPro_Personal_License/Prospectus Pro Demo Fonts v1-73/web/ProspectusProLDEMO-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'ProspectusPro';
  src: url('./fonts/ProspectusPro_Personal_License/Prospectus Pro Demo Fonts v1-73/web/ProspectusProLDEMO-Bold.woff2') format('woff2'),
       url('./fonts/ProspectusPro_Personal_License/Prospectus Pro Demo Fonts v1-73/web/ProspectusProLDEMO-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'ProspectusPro';
  src: url('./fonts/ProspectusPro_Personal_License/Prospectus Pro Demo Fonts v1-73/web/ProspectusProLDEMO-BoldItalic.woff2') format('woff2'),
       url('./fonts/ProspectusPro_Personal_License/Prospectus Pro Demo Fonts v1-73/web/ProspectusProLDEMO-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'ProspectusPro';
  src: url('./fonts/ProspectusPro_Personal_License/Prospectus Pro Demo Fonts v1-73/web/ProspectusProLDEMO-Italic.woff2') format('woff2'),
       url('./fonts/ProspectusPro_Personal_License/Prospectus Pro Demo Fonts v1-73/web/ProspectusProLDEMO-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'ProspectusPro';
  src: url('./fonts/ProspectusPro_Personal_License/Prospectus Pro Demo Fonts v1-73/web/ProspectusProLDEMO-Light.woff2') format('woff2'),
       url('./fonts/ProspectusPro_Personal_License/Prospectus Pro Demo Fonts v1-73/web/ProspectusProLDEMO-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'ProspectusPro';
  src: url('./fonts/ProspectusPro_Personal_License/Prospectus Pro Demo Fonts v1-73/web/ProspectusProLDEMO-LightItalic.woff2') format('woff2'),
       url('./fonts/ProspectusPro_Personal_License/Prospectus Pro Demo Fonts v1-73/web/ProspectusProLDEMO-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'ProspectusPro';
  src: url('./fonts/ProspectusPro_Personal_License/Prospectus Pro Demo Fonts v1-73/web/ProspectusProLDEMO-Regular.woff2') format('woff2'),
       url('./fonts/ProspectusPro_Personal_License/Prospectus Pro Demo Fonts v1-73/web/ProspectusProLDEMO-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ProspectusPro';
  src: url('./fonts/ProspectusPro_Personal_License/Prospectus Pro Demo Fonts v1-73/web/ProspectusProLDEMO-SemiBold.woff2') format('woff2'),
       url('./fonts/ProspectusPro_Personal_License/Prospectus Pro Demo Fonts v1-73/web/ProspectusProLDEMO-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'ProspectusPro';
  src: url('./fonts/ProspectusPro_Personal_License/Prospectus Pro Demo Fonts v1-73/web/ProspectusProLDEMO-SemiBoldItalic.woff2') format('woff2'),
       url('./fonts/ProspectusPro_Personal_License/Prospectus Pro Demo Fonts v1-73/web/ProspectusProLDEMO-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'ProspectusPro';
  src: url('./fonts/ProspectusPro_Personal_License/Prospectus Pro Demo Fonts v1-73/web/ProspectusProLDEMO-Thin.woff2') format('woff2'),
       url('./fonts/ProspectusPro_Personal_License/Prospectus Pro Demo Fonts v1-73/web/ProspectusProLDEMO-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'ProspectusPro';
  src: url('./fonts/ProspectusPro_Personal_License/Prospectus Pro Demo Fonts v1-73/web/ProspectusProLDEMO-ThinItalic.woff2') format('woff2'),
       url('./fonts/ProspectusPro_Personal_License/Prospectus Pro Demo Fonts v1-73/web/ProspectusProLDEMO-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
}

/* Prospectus Pro Regular */
@font-face {
  font-family: 'ProspectusPro-Regular';
  src: url('./fonts/ProspectusPro_Personal_License/Prospectus Pro Demo Fonts v1-73/web/ProspectusProLDEMO-Regular.woff2') format('woff2'),
       url('./fonts/ProspectusPro_Personal_License/Prospectus Pro Demo Fonts v1-73/web/ProspectusProLDEMO-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/* Prospectus Pro Bold */
@font-face {
  font-family: 'ProspectusPro-Bold';
  src: url('./fonts/ProspectusPro_Personal_License/Prospectus Pro Demo Fonts v1-73/web/ProspectusProLDEMO-Bold.woff2') format('woff2'),
       url('./fonts/ProspectusPro_Personal_License/Prospectus Pro Demo Fonts v1-73/web/ProspectusProLDEMO-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

/* Prospectus Pro Italic */
@font-face {
  font-family: 'ProspectusPro-Italic';
  src: url('./fonts/ProspectusPro_Personal_License/Prospectus Pro Demo Fonts v1-73/web/ProspectusProLDEMO-Italic.woff2') format('woff2'),
       url('./fonts/ProspectusPro_Personal_License/Prospectus Pro Demo Fonts v1-73/web/ProspectusProLDEMO-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}