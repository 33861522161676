/* Card.css */

/* Container for the card to handle the flipping effect */
.card-container {
  perspective: 1000px; /* Allows the 3D effect */
  height: 325px; /* Adjust this value to set the overall height of the card container */
}

/* This style defines the main card container */
.card {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d; /* Enables 3D effect */
  transition: transform 0.6s; /* Smooth flipping animation */
}

.card.flipped {
  transform: rotateY(180deg); /* Flips the card */
}

/* Front and back face of the card */
.card-front, .card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden; /* Hides the back face when not flipped */
}

.card-front {
  /* Styles for the front face */
  background-color: #3D5CA7;
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-image {
  width: 100%;
  height: auto;
}

.card-content-box {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(255, 255, 255, 1);
  padding: 0.6em;
  width: 80%;
  height: auto;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: 'ProspectusPro-Regular', serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.card h3 {
  margin: 0;
  margin-top: 0em;
  font-size: 1.5em;
  font-weight: bold;
  font-family: 'ProspectusPro-Bold', serif;
  line-height: 1.2; /* Adjusts the space between lines */
  word-spacing: 0.1em; /* Adjusts the space between words */
}

.card p {
  margin: 0.3em 0;
  font-size: 0.9em;
  font-family: 'ProspectusPro-Regular', serif;
}

.creator-role {
  text-transform: uppercase;
  font-weight: bold;
  color: #3D5CA7;
  font-size: 1em;
  font-family: 'ProspectusPro-Bold', serif;
}

.creator-name {
  margin-left: 0.5em;
  font-size: 1em;
  font-family: 'ProspectusPro-Regular', serif;
}

.card-year {
  margin-top: 0em;
  font-size: 1em;
  font-family: 'ProspectusPro-Regular', serif;
}

.card select {
  margin-top: 0em;
  padding: 0.5em;
  border-radius: 3px;
  font-size: 0.8em;
  font-family: 'ProspectusPro-Regular', serif;
}

/* New style for positioning the select element */
.card-select {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 0.5em;
  border-radius: 5px;
  font-size: 0.8em;
  font-family: 'ProspectusPro-Bold', serif;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Styles for the back face */
.card-back {
  transform: rotateY(180deg); /* Flips the back face */
  background-color: rgba(255, 255, 255, 0.8); /* Slightly transparent white background */
  background-size: cover; /* Ensure the image covers the entire background */
  background-position: center; /* Center the image */
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column; /* Changed to column layout */
  align-items: center;
  justify-content: center;
  padding: 1em;
}

.card-back-content {
  background-color: rgba(255, 255, 255, 1); /* White background */
  padding: 1em;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%; /* Wider content box */
  height: 100%; /* Fixed height as a percentage of the card */
  font-family: 'ProspectusPro-Regular', serif;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensures space distribution */
  align-items: center;
  overflow: hidden;
}

.card-back-content h3 {
  margin-top: 0em; /* Add some margin for spacing */
  font-size: 1.2em; /* Font size */
  font-weight: bold; /* Font weight */
  font-family: 'ProspectusPro-Bold', serif; /* Font family */
}

.card-back-content .recommended-by {
  font-size: 0.9em;
  font-style: italic;
  font-weight: bold;
  margin-bottom: 1em;
  color: #868686;
}

.card-back-content .description-header {
  margin-top: 0.5em;
  font-size: 1em;
  font-weight: bold;
  text-align: left;
  width: 100%;
  margin-bottom: 0em;
}

.card-back-content .consumption-info {
  font-size: 0.8em;
  font-weight: bold;
  margin-top: 0em; /* Reduced space above the consumption info */
  margin-bottom: 0em; /* Adjusts space below the consumption info */
}

.card-back-content .consumption-info span {
  display: block;
}

/* Flip icon styles */
.flip-icon {
  position: absolute;
  bottom: 15px;
  right: 25px;
  font-size: 1.5em;
  color: #e0dddd;
  cursor: pointer;
  transition: color 0.3s;
}

.flip-icon:hover {
  color: #ccc;
}

/* Media Queries for Responsive Design */
@media (max-width: 1200px) {
  .card-container {
    height: 350px;
  }
  .card-content-box {
    padding: 0.8em;
  }
  .card h3 {
    font-size: 1.4em;
  }
  .card p, .creator-role, .creator-name, .card-year {
    font-size: 0.9em;
  }
}

@media (max-width: 992px) {
  .card-container {
    height: 300px;
  }
  .card-content-box {
    padding: 0.6em;
  }
  .card h3 {
    font-size: 1.3em;
  }
  .card p, .creator-role, .creator-name, .card-year {
    font-size: 0.8em;
  }
}

@media (max-width: 768px) {
  .card-container {
    height: 250px;
  }
  .card-content-box {
    padding: 0.5em;
  }
  .card h3 {
    font-size: 1.2em;
  }
  .card p, .creator-role, .creator-name, .card-year {
    font-size: 0.7em;
  }
}

@media (max-width: 576px) {
  .card-container {
    height: 200px;
  }
  .card-content-box {
    padding: 0.4em;
  }
  .card h3 {
    font-size: 1.1em;
  }
  .card p, .creator-role, .creator-name, .card-year {
    font-size: 0.6em;
  }
}