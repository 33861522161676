:root {
    --card-spacing: 75px; /* Adjust this value to change spacing */
  }
  
  .stacked-card-list {
    display: flex;
    align-items: center;
    position: relative;
    height: 220px; /* Adjust the height to fit the cards */
  }
  
  .stacked-card-wrapper {
    position: absolute;
    transition: transform 0.3s;
    width: 200px; /* Adjust width as needed */
    height: 100%; /* Use the full height of the container */
  }
  
  .stacked-card-wrapper:hover {
    transform: translateX(-10px) scale(1.1); /* Adjust hover effects */
    z-index: 10; /* Bring to front on hover */
  }
  
  .stacked-card-wrapper:nth-child(1) { left: 0; }
  .stacked-card-wrapper:nth-child(2) { left: var(--card-spacing); }
  .stacked-card-wrapper:nth-child(3) { left: calc(var(--card-spacing) * 2); }
  /* Add more nth-child selectors as needed for additional cards */