/* Sidebar.css */

/* Styling for the sidebar container */
.sidebar {
    width: 250px; /* Increase the width */
    background-color: rgba(255, 255, 255, 0.8); /* Slightly transparent white background */
    border-radius: 10px; /* Rounding the corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adding a subtle shadow */
    padding: 20px; /* Adding some padding */
    margin-right: 20px; /* Adding some margin to the right */
  }
  
  /* Styling for the sidebar list */
  .sidebar ul {
    list-style-type: none; /* Removing default list styling */
    padding: 0; /* Removing default padding */
    margin: 0; /* Removing default margin */
  }
  
  /* Styling for each list item */
  .sidebar ul li {
    padding: 10px 15px; /* Adding padding */
    margin-bottom: 10px; /* Adding some space between items */
    border-radius: 5px; /* Rounding the corners */
    cursor: pointer; /* Changing the cursor on hover */
    transition: background-color 0.3s ease; /* Adding a transition effect */
    white-space: nowrap; /* Prevent line break */
  }
  
  /* Styling for selected list items */
  .sidebar ul li.selected {
    background-color: #007bff; /* Blue background for selected items */
    color: white; /* White text for selected items */
  }
  
  /* Hover effect for list items */
  .sidebar ul li:hover {
    background-color: #0056b3; /* Darker blue background on hover */
    color: white; /* White text on hover */
  }
  
  /* Styling for sidebar headers */
  .sidebar h3 {
    margin-bottom: 10px;
    padding-bottom: 5px;
    border-bottom: 1px solid #ddd;
    font-size: 1.2em;
    color: #333;
  }
  
  /* Horizontal rule for separation */
  .sidebar hr {
    margin: 20px 0;
    border: none;
    border-top: 1px solid #ddd;
  }