.tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  background: linear-gradient(to bottom, #f4f4f4, #eaeaea); /* Subtle gradient background */
  padding: 10px 0; /* Vertical padding */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Slight shadow for separation */
}

.tab {
  margin: 0 5px;
  padding: 10px 20px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.tab:hover {
  background-color: #0056b3;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Deeper shadow on hover */
}

.tab.active {
  background-color: #0056b3;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Deeper shadow */
}