.condensed-card-list {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .condensed-card-wrapper {
    position: relative;
    width: 100%;
    margin: 10px 0;
  }
  
  /* 1. Adjust the size of the whole card here */
  .condensed-card {
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    position: relative;
    background-size: cover;
    background-position: center;
    height: 50px; /* Adjusted height */
    width: 100%; /* Adjust the width as needed */
  }
  
  /* 2. Adjust the gradient, position, and size of the black box here */
  .condensed-card-title-box {
    display: flex;
    align-items: center;
    padding: 30px;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
    border-radius: 5px 0 0 5px;
    z-index: 1;
    height: 100%; /* Adjust the height of the black box */
    width: 75%; /* Adjust the width of the black box */
    left: 0; /* Adjust the position (left or right) */
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide overflow text */
    text-overflow: ellipsis; /* Add ellipsis if text overflows */
  }
  
  .condensed-card-title {
    font-size: 1.5em;
    font-weight: bold;
    margin-left: 0px;
    z-index: 1;
    font-family: 'ProspectusPro-Bold', serif; /* Ensure font consistency */
    color: white; /* Set title color to white */
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide overflow text */
    text-overflow: ellipsis; /* Add ellipsis if text overflows */
  }
  
  .full-card {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: none;
    z-index: 10;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease-in-out;
  }
  
  .condensed-card-wrapper:hover .full-card {
    display: block;
  }